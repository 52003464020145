<template>
  <div>
    <div class="head">
      <van-row>
        <van-col span="4"> </van-col>
        <van-col span="12">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 60px;
            "
          >
            <!--<img-->
              <!--v-if="selectNetWork == 1"-->
              <!--:src="require('@/assets/image/trc.png')"-->
              <!--alt=""-->
              <!--style="width: 18px; height: 18px"-->
              <!--@click="showPopover = !showPopover"-->
            <!--/>-->
            <img
              v-if="selectNetWork == 2"
              :src="require('@/assets/image/erc.png')"
              alt=""
              style="width: 18px; height: 18px"
              @click="showPopover = !showPopover"
            />
            <!--<img-->
              <!--v-if="selectNetWork == 3"-->
              <!--:src="require('@/assets/image/usdc.png')"-->
              <!--alt=""-->
              <!--style="width: 18px; height: 18px"-->
              <!--@click="showPopover = !showPopover"-->
            <!--/>-->
            <img
              v-if="selectNetWork == 4"
              :src="require('@/assets/image/biance.png')"
              alt=""
              style="width: 18px; height: 18px"
              @click="showPopover = !showPopover"
            />
            <van-popover :show="showPopover" trigger="click" placement="bottom">
              <div
                style="
                  background-color: rgb(44, 44, 83);
                  color: rgb(112, 128, 179);
                  padding: 20px 15px;
                  box-sizing: border-box;
                  font-size: 0.75rem;
                  width: 200px;
                "
              >
                <van-row>
                  <van-col span="24">
                    {{ $t("select_network") }}
                  </van-col>
                </van-row>
                <van-row style="margin-top: 20px" align="center">
                  <van-col v-if="false"  span="24">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img
                            :src="require('@/assets/image/trc.png')"
                            alt=""
                            style="width: 18px; height: 18px"
                            @click="flushWallet(1)"
                          />
                          <span
                            style="margin-left: 10px"
                            @click="flushWallet(1)"
                            >TRC20</span
                          >
                        </div>
                      </van-col>
                      <van-col span="8">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <van-col span="24" style="margin-top: 15px">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img
                            :src="require('@/assets/image/erc.png')"
                            alt=""
                            style="width: 18px; height: 18px"
                            @click="flushWallet(2)"
                          />
                          <span
                            style="margin-left: 10px"
                            @click="flushWallet(2)"
                            >ERC20</span
                          >
                        </div>
                      </van-col>
                      <van-col span="8">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <van-col v-if="false"  span="24" style="margin-top: 15px">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img
                            :src="require('@/assets/image/usdc.png')"
                            alt=""
                            style="width: 18px; height: 18px"
                            @click="flushWallet(3)"
                          />
                          <span
                            style="margin-left: 10px"
                            @click="flushWallet(3)"
                            >USDC</span
                          >
                        </div>
                      </van-col>
                      <van-col span="8">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                  <van-col span="24" style="margin-top: 15px">
                    <van-row>
                      <van-col span="18">
                        <div style="display: flex; align-items: center">
                          <img
                            :src="require('@/assets/image/biance.png')"
                            alt=""
                            style="width: 18px; height: 18px"
                            @click="flushWallet(4)"
                          />
                          <span
                            style="margin-left: 10px"
                            @click="flushWallet(4)"
                            >Binance</span
                          >
                        </div>
                      </van-col>
                      <van-col span="8">
                        <van-icon name="star" />
                      </van-col>
                    </van-row>
                  </van-col>
                </van-row>
              </div>
              <template #reference>
                <van-icon
                  name="arrow-down"
                  size="15"
                  style="margin-left: 10px; color: #ffffff"
                  @click="showPopover = !showPopover"
                ></van-icon>
              </template>
            </van-popover>
            <van-button
              size="small"
              round
              color="rgba(109,84,235,0.8)"
              style="margin-left: 20px"
              @click="flushWallet2()"
              >{{ $t("connect_wallet") }}</van-button
            >
          </div>
        </van-col>
        <van-col span="8" style="align-items: center">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 60px;
              width: 100%;
            "
          >
            <div
              style="
                width: 28px;
                height: 15px;
                line-height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(44, 44, 83, 0.3);
                padding: 10px 20px;
                border-radius: 15px;
              "
            >
              <!-- <van-icond
                name="chat-o"
                style="color: #ffffff; font-size: 20px"
                dot
              ></van-icon> -->
              <img
                :src="getLangLogo"
                alt=""
                style="width: 18px; height: 18px; margin-left: 15px"
              />
              <van-popover :show="langShow" trigger="click" placement="bottom">
                <div
                  style="
                    background-color: rgb(44, 44, 83);
                    color: rgb(112, 128, 179);
                    padding: 20px 15px;
                    box-sizing: border-box;
                    font-size: 0.75rem;
                    width: 200px;
                  "
                >
                  <van-row>
                    <van-col span="24"> 选择语言 </van-col>
                  </van-row>
                  <van-row style="margin-top: 20px" align="center">
                    <van-col span="24" @click="changeLang('en')">
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img
                              :src="require('@/assets/image/usd.png')"
                              alt=""
                              style="width: 18px; height: 18px"
                            />
                            <span style="margin-left: 10px">English</span>
                          </div>
                        </van-col>
                        <van-col span="8">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col
                      span="24"
                      style="margin-top: 15px"
                      @click="changeLang('cn')"
                    >
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img
                              :src="require('@/assets/image/china.svg')"
                              alt=""
                              style="width: 18px; height: 18px"
                            />
                            <span style="margin-left: 10px">Chinese</span>
                          </div>
                        </van-col>
                        <van-col span="8">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col
                      span="24"
                      style="margin-top: 15px"
                      @click="changeLang('jp')"
                    >
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img
                              :src="require('@/assets/image/japan.png')"
                              alt=""
                              style="width: 18px; height: 18px"
                            />
                            <span style="margin-left: 10px">Japan</span>
                          </div>
                        </van-col>
                        <van-col span="8">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col
                      span="24"
                      style="margin-top: 15px"
                      @click="changeLang('sp')"
                    >
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img
                              :src="require('@/assets/image/spain.png')"
                              alt=""
                              style="width: 18px; height: 18px"
                            />
                            <span style="margin-left: 10px">Spain</span>
                          </div>
                        </van-col>
                        <van-col span="8">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col
                      span="24"
                      style="margin-top: 15px"
                      @click="changeLang('ru')"
                    >
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img
                              :src="require('@/assets/image/russian.png')"
                              alt=""
                              style="width: 18px; height: 18px"
                            />
                            <span style="margin-left: 10px">Russian</span>
                          </div>
                        </van-col>
                        <van-col span="8">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>
                    </van-col>
                    <van-col
                      span="24"
                      style="margin-top: 15px"
                      @click="changeLang('kor')"
                    >
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img
                              :src="require('@/assets/image/korean.png')"
                              alt=""
                              style="width: 18px; height: 18px"
                            />
                            <span style="margin-left: 10px">Korean</span>
                          </div>
                        </van-col>
                        <van-col span="8">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>

                    </van-col>

                    <van-col
                        span="24"
                        style="margin-top: 15px"
                        @click="changeLang('th')"
                    >
                      <van-row>
                        <van-col span="18">
                          <div style="display: flex; align-items: center">
                            <img
                                :src="require('@/assets/image/th.png')"
                                alt=""
                                style="width: 18px; height: 18px"
                            />
                            <span style="margin-left: 10px">Thailand</span>
                          </div>
                        </van-col>
                        <van-col span="8">
                          <van-icon name="star" />
                        </van-col>
                      </van-row>

                    </van-col>
                  </van-row>
                </div>
                <template #reference>
                  <van-icon
                    name="arrow-down"
                    size="15"
                    style="margin-left: 10px; color: #ffffff"
                    @click="langShow = !langShow"
                  ></van-icon>
                </template>
              </van-popover>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
  <router-view />
  <div
    style="
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 80px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: #212244;
    "
  >
    <van-row style="margin-top: 10px">
      <van-col
        span="8"
        style="font-size: 15px; text-align: center"
        @click="goto('/')"
      >
        <img :src="getHomeImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
      <!--<van-col-->
        <!--span="8"-->
        <!--style="font-size: 15px; text-align: center"-->
        <!--@click="goto('/change')"-->
      <!--&gt;-->
        <!--<img :src="getChangeImg" alt="" style="width: 30px; height: 30px" />-->
      <!--</van-col>-->
      <van-col
        span="8"
        style="font-size: 15px; text-align: center"
        @click="goto('/service')"
      >
        <img :src="getServiceImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
      <van-col
        span="8"
        style="font-size: 15px; text-align: center"
        @click="goto('my')"
      >
        <img :src="getMyImg" alt="" style="width: 30px; height: 30px" />
      </van-col>
    </van-row>
    <van-row style="margin-top: 10px; color: #a9abfc">
      <van-col
        span="8"
        style="font-size: 12px; text-align: center"
        @click="goto('/')"
      >
        {{ $t("shouye") }}
      </van-col>
      <!--<van-col-->
        <!--span="8"-->
        <!--style="font-size: 12px; text-align: center"-->
        <!--@click="goto('/change')"-->
      <!--&gt;-->
        <!--{{ $t("duihuan") }}-->
      <!--</van-col>-->
      <van-col
        span="8"
        style="font-size: 12px; text-align: center"
        @click="goto('/service')"
      >
        {{ $t("fuwu") }}
      </van-col>
      <van-col
        span="8"
        style="font-size: 12px; text-align: center"
        @click="goto('/my')"
      >
        {{ $t("geren") }}
      </van-col>
    </van-row>



    <van-popup :round="true" v-model:show="showNotice">
      <div class="notice-dom">
        {{content}}

        <div style="display: flex;justify-content: center;margin-top: 30px">
          <van-button @click="knowContent" type="primary">
            {{ $t("sureText") }}
          </van-button>

        </div>
      </div>
    </van-popup>

  </div>


  <!--<div class="fixed" @click="goLink()">-->
    <!--<img src="@/assets/image/kefu.png"/>-->
  <!--</div>-->
</template>

<script>
    import { Dialog } from 'vant';
   // import ethers from 'ethers'
export default {
  data() {
    return {
      erc: require("../assets/image/erc.png"),
      lang: require("../assets/image/china.svg"),
      showPopover: false,
      active: 1,
      web3: "",
      tronweb: "",
      balance: "",
      address: "",
      langShow: false,
      selectNetWork: "",
      parentCode: "",
      linkUrl:'',
      showNotice:false,
      notice:{}
    };
  },
  computed: {
    sureText(){

    },
    content(){
        return this.notice[this.$i18n.locale + 'Content']
    },
    getHomeImg() {
      return this.$route.path == "/home"
        ? require("@/assets/image/home_select.png")
        : require("@/assets/image/home.png");
    },
    getChangeImg() {
      return this.$route.path == "/change"
        ? require("@/assets/image/change_select.png")
        : require("@/assets/image/change.png");
    },
    getServiceImg() {
      return this.$route.path == "/service"
        ? require("@/assets/image/service_select.png")
        : require("@/assets/image/service.png");
    },
    getMyImg() {
      return this.$route.path == "/my"
        ? require("@/assets/image/my_select.png")
        : require("@/assets/image/my.png");
    },
    getLangLogo() {
      switch (this.$i18n.locale) {
        case "en":
          return require("@/assets/image/usd.png");
          break;
        case "cn":
          return require("@/assets/image/china.svg");
          break;
        case "jp":
          return require("@/assets/image/japan.png");
          break;
        case "kor":
          return require("@/assets/image/korean.png");
          break;
        case "sp":
          return require("@/assets/image/spain.png");
          break;
        case "ru":
          return require("@/assets/image/russian.png");
          break;
        case "th":
          return require("@/assets/image/th.png");
          break;
        default:
          return require("@/assets/image/usd.png");
      }
    },
  },
  watch() {},
    async created(){
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        // const accounts = await window.tronLink.request({method: 'tron_requestAccounts'})
      this.getNotice()
    },
  methods: {
    //fixed
      knowContent(){
          localStorage.setItem('saveNotice',this.notice.id)
          this.showNotice = false
      },
      getNotice(){
          this.$request
              .get("userNotice/queryNoticeInfo")
              .then((res) => {
                  console.log(res.data)
                    let result = res.data.result || {}
                      this.notice = result;
                    let saveNotice = localStorage.getItem('saveNotice')
                    if(saveNotice != result.id){
                        this.showNotice = true
                    }


              });
      },
    goLink(){
      window.open(this.linkUrl)
    },
    flushWallet(netWork) {
      this.showPopover = false;
      this.selectNetWork = netWork;
        sessionStorage.setItem("selectNetWork", this.selectNetWork);
      if (netWork == 1) {
        // trc
        this.getTrcWallet();
      } else if (netWork == 2) {
        // erc
        this.getERCwallet();
      } else if (netWork == 3) {
        // usdc
        this.getUsdcWallet();
      } else if (netWork == 4) {
        // bsc
        this.getBinanceWallet();
      }
    },
    flushWallet2() {
      //alert("selectNetWork: " + this.selectNetWork); // 弹窗显示selectNetWork的值
      if (this.selectNetWork == 1) {
        // trc
        this.getTrcWallet();
      } else if (this.selectNetWork == 2) {
        // erc
        this.getERCwallet();
      } else if (this.selectNetWork == 3) {
        // usdc
        this.getUsdcWallet();
      } else if (this.selectNetWork == 4) {
        // bsc
        this.getBinanceWallet();
      }
    },
    async getTrcWallet() {
        if(window.tronWeb){

            let count = 0
            let that  = this
            let inter = setInterval(()=>{
                if(count > 8 || window.tronWeb.defaultAddress.base58){
                    clearInterval(inter)

                    that.tronweb = window.tronWeb;
                    that.address = that.tronweb.defaultAddress.base58;
                    if (that.address && that.address != "false") {
                        that.register();
                    }
                }
                count = count + 1

            },500)

        }else{
            let msg = '',okText=';'
            switch (this.$i18n.locale) {
                case "en":
                    msg = 'Your device does not have a wallet installed';
                    break;
                case "cn":
                    msg = '您的设备未安装钱包'
                    break;
                case "jp":
                    msg = 'デバイスにウォレットがインストールされていません'
                    break;
                case "kor":
                    msg = '장치에 지갑이 설치되어 있지 않습니다.'
                    break;
                case "sp":
                    msg = 'Su dispositivo no tiene billetera instalada'
                    break;
                case "ru":
                    msg = 'На вашем устройстве не установлен кошелек'
                    break;
                case "th":
                    msg = 'ยังไม่มีการติดตั้งกระเป๋าสตางค์บนอุปกรณ์ของคุณ'
                    break;

            }
            Dialog({
                message: msg,
                confirmButtonText:'confirm',
            }).then(() => {
                window.location.href = 'https://www.tronlink.org/'
            });
        }

    },
    getERCwallet() {
      this.getBinanceWallet();
    },
    async getUsdcWallet() {
      this.getBinanceWallet();
    },
    async getBinanceWallet() {
      if (typeof window.ethereum !== "undefined") {
      try {
        const accounts = await ethereum.request({ method: "eth_requestAccounts" });
        if (accounts.length > 0) {
          //alert("this.address1: " + await ethereum.request({ method: "eth_accounts" })); // 弹出窗口显示打印的值
          //alert("this.address2: " + ethereum.selectedAddress);
          //alert("this.address3: " + ethereum.defaultAccount);
          this.address = ethereum.selectedAddress;
          if(this.address == undefined){
            const addressArray = await ethereum.request({ method: "eth_accounts" });
            this.address = addressArray.join(", "); // 使用逗号和空格连接数组元素
          }
          //alert("this.address: " + this.address);
          this.register();
        } else {
          // 处理 accounts 为空的情况
        }
      } catch (error) {
        // 处理请求账户失败的情况
        console.error("Failed to request accounts:", error);
      }
    } else {
      //alert("window.ethereum == undefined"); // 弹出窗口显示打印的值
    }
      /*
      if (typeof window.ethereum !== "undefined") {
        //const accounts = await ethereum.request({ method: "eth_requestAccounts" });
        await ethereum
          .request({ method: "eth_requestAccounts" })
          .then((accounts) => {
            if (accounts) {
              alert("this.address1: " + ethereum.selectedAddress); // 弹出窗口显示打印的值
              alert("this.address2: " + ethereum.selectedAccount); 
              alert("this.address3: " + ethereum.defaultAccount); 
              this.address = ethereum.selectedAddress;
              this.register();
            } else {
            }
          })
          .catch(function (reason) {});
      }else{
        alert("window.ethereum==undefined"); // 弹出窗口显示打印的值
      }*/
    },
    // 注册账号
    async register() {
      if (this.address == "false") {
        return this.$toast(this.$t("joinError"));
      }
      console.log("username:", this.address); // 打印代码
      //alert("username: " + this.address); // 弹出窗口显示打印的值
      this.$request
        .post("nft/scUser/userLogin", {
          username: this.address,
          parentCode: this.parentCode,
          networkType: this.selectNetWork,
        })
        .then((res) => {
          if (res.data.code == 200) {
            sessionStorage.setItem("address", this.address);
            sessionStorage.setItem("selectNetWork", this.selectNetWork);
            sessionStorage.setItem("token", res.data.result.token);
            location.reload();
            console.log("okusername:", this.address); // 打印代码
          } else {
            return this.$toast(res.data.message);
          }
        });
    },
    goto(url) {
      this.$router.push(url);
    },
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
      this.langShow = false;
    },
    getInfo() {
      this.$request.post("nft/home/getContractInfo", "").then((res) => {
        if (res.data.code === 200) {
          this.linkUrl = res.data.result.systemSetting.linkUrl;
        }
      });
    },
  },
  mounted() {
    this.getInfo()
    this.selectNetWork = sessionStorage.getItem("selectNetWork");
    if (!this.selectNetWork) {
      this.selectNetWork = 2;
      sessionStorage.setItem("selectNetWork", 2);
    }
    if (this.$route.query.invite_code) {
      this.parentCode = this.$route.query.invite_code;
    }
  },
};
</script>

<style>
.head {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: rgba(33, 34, 68, 1);
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(33, 34, 68, 1);
}
.fixed{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(208, 211, 16, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 100px;
  right: 20px;
  position: fixed;
}
.fixed img{
  width: 36px;
  height: 36px;
}

  .notice-dom{
    width: 60vw;
    padding: 20px;
    color: #333;
    font-size: 1rem;
  }
</style>
